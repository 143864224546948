@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap");

body {
  font-family: "IBM Plex Sans", sans-serif !important;
}

.auth-error {
  color: darkred;
}

.cover {
  height: 100vh;
}

.title {
  position: absolute;
  top: 40vh;
  width: 100%;
}

.toolbar {
  height: 4rem;
}

.fa-heart {
  color: darkred;
  font-size: 14px !important;
}

input[type="checkbox"] {
  display: none;
}

label {
  border: 1px solid #fff;
  /* padding: 10px; */
  display: block;
  position: relative;
  /* margin: 10px; */
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  /* height: 100px;
  width: 100px; */
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + label {
  border-color: #ddd;
}

:checked + label::before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 4 !important;
  }
}

.transparent {
  background-color: rgba(255, 255, 255, 0.5);
}

.react-images__view-image {
  max-height: 95vh !important;
}
